@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

/* Global scrolling fix - ensures scrolling works regardless of cursor position */
:root, #root {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* Reset some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  height: 100%;
  width: 100%;
  overflow-y: auto; /* Changed from overflow-x: hidden to ensure vertical scrolling works */
}

body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  color: #f3f4f6;
  background-color: #050714; /* Updated to match galaxy background */
  overflow-y: auto; /* Ensure vertical scrolling works */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* App container */
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* Background is now handled by DynamicBackground component */
  background: transparent;
  color: #f3f4f6;
  position: relative;
  margin: 0;
  padding: 0;
  overflow-y: auto; /* Ensure vertical scrolling works */
  width: 100%; /* Ensure it takes full width */
}

/* Main content container */
.main-content-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  position: relative;
  z-index: 10;
  padding-top: 3rem;
  padding-bottom: 3rem;
  overflow-y: visible; /* Ensure content can be scrolled */
}

/* Content wrapper */
.content-wrapper {
  max-width: 4xl;
  margin: 0 auto;
  background-color: rgba(15, 23, 42, 0.3);
  backdrop-filter: blur(8px);
  border-radius: 0.75rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(75, 85, 99, 0.3);
  padding: 1.5rem;
  margin-bottom: 0;
  width: 100%;
  overflow: visible; /* Ensure content can be scrolled */
}

/* Animation classes for background blobs */
@keyframes blob {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  33% {
    transform: translate(-50%, -50%) scale(1.1);
  }
  66% {
    transform: translate(-50%, -50%) scale(0.9);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

/* Enhanced animation for twinkling stars in galaxy background */
@keyframes twinkle {
  0% {
    opacity: 0.3;
    transform: scale(0.8);
    filter: blur(1px);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
    filter: blur(0px);
  }
  100% {
    opacity: 0.3;
    transform: scale(0.8);
    filter: blur(1px);
  }
}

/* Animation for nebula rotation */
@keyframes nebulaRotate {
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

/* Animation for cosmic dust */
@keyframes cosmicDrift {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(10px) translateX(-10px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

.animate-blob {
  animation: blob 7s infinite;
}

.animation-delay-2000 {
  animation-delay: 2s;
}

.animation-delay-4000 {
  animation-delay: 4s;
}

.animate-nebula {
  animation: nebulaRotate 120s infinite linear;
}

.animate-cosmic-drift {
  animation: cosmicDrift 30s infinite ease-in-out;
}

/* Input styling */
input[type="text"],
input[type="number"],
select,
textarea {
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid rgba(107, 114, 128, 0.5);
  background-color: rgba(31, 41, 55, 0.5);
  color: #f3f4f6;
  font-family: 'Inter', sans-serif;
  backdrop-filter: blur(4px);
  transition: all 0.2s ease;
}

/* Style for input placeholders */
input::placeholder,
textarea::placeholder,
select::placeholder {
  color: rgba(156, 163, 175, 0.7);
}

/* Focus styles for inputs */
input:focus,
textarea:focus,
select:focus {
  outline: none;
  border-color: rgba(139, 92, 246, 0.8);
  box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.3);
}

/* Table styling */
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin: 1.5rem 0;
  border-radius: 0.5rem;
  overflow: hidden;
  background-color: rgba(31, 41, 55, 0.5);
  backdrop-filter: blur(4px);
  border: 1px solid rgba(75, 85, 99, 0.5);
}

th, td {
  padding: 0.75rem 1rem;
  text-align: left;
  border-bottom: 1px solid rgba(75, 85, 99, 0.5);
}

th {
  background-color: rgba(55, 65, 81, 0.7);
  font-weight: 600;
  color: #f3f4f6;
  text-transform: uppercase;
  font-size: 0.75rem;
  letter-spacing: 0.05em;
}

tr:last-child td {
  border-bottom: none;
}

tr:hover {
  background-color: rgba(55, 65, 81, 0.3);
}

/* Button styling - using Tailwind classes in components instead */

/* Footer styling - using Tailwind classes in components instead */

/* Responsive design - using Tailwind classes in components instead */

/* Project specific styling */
.finance-project-1,
.finance-project-2,
.finance-project-3 {
  padding: 1.5rem;
  border-radius: 0.5rem;
  background-color: rgba(31, 41, 55, 0.3);
  backdrop-filter: blur(4px);
}

/* Transitions */
.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
}

/* Radix UI animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(2px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.animate-slideDownAndFade {
  animation: slideDownAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
}

.animate-slideUpAndFade {
  animation: slideUpAndFade 400ms cubic-bezier(0.16, 1, 0.3, 1);
}

/* Media queries */
@media (min-width: 768px) {
  .main-content-container {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  
  .content-wrapper {
    padding: 2rem;
  }
}

@media (min-width: 1024px) {
  .main-content-container {
    padding-top: 3rem;
  }
}

/* Keep any other specific styles that might be needed */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

h1 {
  font-size: 4rem;
  font-weight: 600;
  margin-bottom: 1rem;
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(255, 0, 255, 0.5);
  text-align: center;
}

p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: #cccccc;
  text-align: center;
}

.btn {
  font-family: 'Poppins', sans-serif;
  display: inline-block;
  padding: 12px 24px;
  border: none;
  text-decoration: none;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  border-radius: 50px;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
}

.btn-primary {
  background: linear-gradient(45deg, #ff00ff, #00ffff);
  color: #ffffff;
  box-shadow: 0 0 20px rgba(255, 0, 255, 0.5);
}

.btn-primary:hover {
  transform: translateY(-3px);
  box-shadow: 0 0 30px rgba(255, 0, 255, 0.7);
}

.btn-secondary {
  background: transparent;
  color: #ffffff;
  border: 2px solid #00ffff;
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.3);
  margin: 0 10px;
}

.btn-secondary:hover {
  background: rgba(0, 255, 255, 0.1);
  box-shadow: 0 0 30px rgba(0, 255, 255, 0.5);
  transform: translateY(-3px);
}

footer {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  padding: 1rem 0;
  margin-top: 2rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.footer-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
}

.footer-copyright {
  margin: 0;
  line-height: 1;
}

.copyright {
  font-size: 0.8rem;
  color: #cccccc;
  text-align: center;
}

.disclaimer-button {
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid #555555; /* Darker grey outline */
  color: #cccccc;
  cursor: pointer;
  font: inherit;
  font-size: 0.9rem;
  padding: 5px 10px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

.disclaimer-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  border-color: #777777; /* Slightly lighter on hover for contrast */
}

.disclaimer-text {
  margin-top: 1rem;
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
}

@media (max-width: 768px) {
  .footer-main {
    flex-direction: column;
  }

  .footer-item {
    width: 100%;
    justify-content: center;
    margin: 0.5rem 0;
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 4rem 0;
  text-align: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.about-me-button {
  margin-bottom: 3rem;
}

.project-buttons {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

/* Color splash effect */
.color-splash {
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  filter: blur(100px);
  opacity: 0.3;
  z-index: -1;
}

.splash-1 {
  top: 10%;
  left: 10%;
  background-color: #ff00ff;
}

.splash-2 {
  bottom: 10%;
  right: 10%;
  background-color: #00ffff;
}

@media (max-width: 768px) {
  h1 {
    font-size: 2.5rem;
  }
  
  .btn {
    font-size: 1rem;
    padding: 10px 20px;
  }
  
  .project-buttons {
    flex-direction: column;
    align-items: center;
  }
  
  .btn-secondary {
    margin: 0.75rem 0;
  }
}

/* Add this new class for the stock input container */
.stock-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

/* Style for the remove button */
.remove-stock-btn {
  padding: 10px;
  background-color: #ff4136;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-stock-btn:hover {
  background-color: #ff1a1a;
}

/* Add these new styles for the FinanceProject3 component */
.finance-project-3 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.finance-project-3 > div {
  width: 100%;
  max-width: 800px;
}

.finance-project-3 table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
}

.finance-project-3 th,
.finance-project-3 td {
  border: none;
}

.finance-project-3 th {
  background-color: #1f2937;
  font-weight: 600;
  color: #d1d5db;
  text-transform: uppercase;
}

.finance-project-3 tr:nth-child(even) {
  background-color: #374151;
}

.finance-project-3 tr:hover {
  background-color: #4b5563;
}

/* Override any conflicting global styles */
.finance-project-3 input[type="text"],
.finance-project-3 input[type="number"],
.finance-project-3 select {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.finance-project-3 input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.finance-project-3 .btn {
  color: #ffffff;
}

/* Update these styles to be more specific */
.finance-project-1 input[type="text"],
.finance-project-1 input[type="number"] {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #00ffff;
  background-color: rgba(0, 255, 255, 0.1);
  color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

/* Custom styling for date inputs */
.date-input-custom {
  padding: 10px 12px !important;
  border-radius: 8px !important;
  border: 1px solid rgba(139, 92, 246, 0.5) !important;
  background-color: rgba(30, 41, 59, 0.8) !important;
  color: #f3f4f6 !important;
  font-family: 'Inter', sans-serif !important;
  transition: all 0.3s ease !important;
  cursor: pointer !important;
  appearance: none !important;
  -webkit-appearance: none !important;
  padding-right: 40px !important; /* Space for the calendar icon */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important;
}

.date-input-custom:hover {
  border-color: rgba(139, 92, 246, 0.8) !important;
  box-shadow: 0 0 0 2px rgba(139, 92, 246, 0.2) !important;
}

.date-input-custom:focus {
  outline: none !important;
  border-color: rgba(139, 92, 246, 1) !important;
  box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.3) !important;
  animation: pulse-border 1.5s ease-in-out infinite;
}

@keyframes pulse-border {
  0% {
    box-shadow: 0 0 0 0 rgba(139, 92, 246, 0.4);
  }
  70% {
    box-shadow: 0 0 0 6px rgba(139, 92, 246, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(139, 92, 246, 0);
  }
}

/* Add new animation for the calendar popup */
@keyframes calendarFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Override Radix UI Popover styling */
[data-radix-popper-content-wrapper] {
  z-index: 50 !important;
}

/* Update placeholder styles to be more specific */
.finance-project-1 input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

/* Update focus styles to be more specific */
.finance-project-1 input:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
}

/* Add these new styles for the table in FinanceProject1 */
.finance-project-1 table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.finance-project-1 th,
.finance-project-1 td {
  padding: 0.75rem 1rem;
  text-align: left;
}

.finance-project-1 th {
  background-color: #1f2937;
  font-weight: 600;
  color: #d1d5db;
  text-transform: uppercase;
}

.finance-project-1 tr:nth-child(even) {
  background-color: #374151;
}

.finance-project-1 tr:hover {
  background-color: #4b5563;
}

/* Add these new styles for the FinanceProject2 component */
.finance-project-2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
}

.finance-project-2 > div {
  width: 100%;
  max-width: 800px;
}

.finance-project-2 table {
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
  margin-bottom: 1.5rem;
}

.finance-project-2 th,
.finance-project-2 td {
  border: 1px solid #4a5568;
  padding: 0.75rem 1rem;
  text-align: left;
}

.finance-project-2 th {
  background-color: #2d3748;
  font-weight: 600;
  color: #e2e8f0;
  text-transform: uppercase;
}

.finance-project-2 tr:nth-child(even) {
  background-color: #2d3748;
}

.finance-project-2 tr:hover {
  background-color: #4a5568;
}

/* Override any conflicting global styles */
.finance-project-2 input[type="text"],
.finance-project-2 input[type="number"],
.finance-project-2 select {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.finance-project-2 input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.finance-project-2 .btn {
  color: #ffffff;
}